import React, { useState } from 'react';
import { Download } from 'lucide-react';
import { downloadEmailList } from '../../lib/utils/emailUtils';

export const EmailManagement: React.FC = () => {
  const [loading, setLoading] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleDownload = async (type: 'marketing' | 'communityNews') => {
    try {
      setLoading(type);
      setError(null);
      await downloadEmailList(type);
    } catch (err) {
      setError('Failed to download subscriber list. Please try again.');
      console.error('Error downloading email list:', err);
    } finally {
      setLoading(null);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Email Subscriber Management</h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <div className="space-y-4">
        <div className="p-4 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Marketing Subscribers</h3>
          <p className="text-gray-600 mb-4">
            Users who have opted in to receive promotional emails and special offers.
          </p>
          <button
            onClick={() => handleDownload('marketing')}
            disabled={loading === 'marketing'}
            className={`flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 ${
              loading === 'marketing' ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <Download className="w-4 h-4 mr-2" />
            {loading === 'marketing' ? 'Downloading...' : 'Download List'}
          </button>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Community News Subscribers</h3>
          <p className="text-gray-600 mb-4">
            Users who have opted in to receive community news and updates.
          </p>
          <button
            onClick={() => handleDownload('communityNews')}
            disabled={loading === 'communityNews'}
            className={`flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 ${
              loading === 'communityNews' ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <Download className="w-4 h-4 mr-2" />
            {loading === 'communityNews' ? 'Downloading...' : 'Download List'}
          </button>
        </div>

        <div className="mt-4 p-4 border-t border-gray-200">
          <h4 className="text-sm font-medium text-gray-900 mb-2">Important Notes:</h4>
          <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
            <li>Only verified email addresses are included</li>
            <li>Lists include user display names for reference</li>
            <li>Downloads are in CSV format</li>
            <li>Lists only include users who explicitly opted in</li>
          </ul>
        </div>
      </div>
    </div>
  );
};