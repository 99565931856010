import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

interface EmailSubscriber {
  email: string;
  displayName: string;
  preferences: {
    marketing: boolean;
    communityNews: boolean;
  };
}

export const getEmailSubscribers = async (type: 'marketing' | 'communityNews'): Promise<EmailSubscriber[]> => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(
      usersRef,
      where(`emailPreferences.${type}`, '==', true),
      where('emailVerified', '==', true)  // Only include verified emails
    );

    const snapshot = await getDocs(q);
    return snapshot.docs
      .map(doc => {
        const data = doc.data();
        return {
          email: data.email,
          displayName: data.displayName,
          preferences: data.emailPreferences
        };
      })
      .filter(user => user.email); // Filter out any users without email addresses
  } catch (error) {
    console.error('Error fetching email subscribers:', error);
    throw error;
  }
};

export const exportEmailList = async (type: 'marketing' | 'communityNews'): Promise<string> => {
  const subscribers = await getEmailSubscribers(type);
  const csvContent = [
    ['Email', 'Display Name', 'Subscription Type'].join(','),
    ...subscribers.map(user => 
      [user.email, user.displayName, type].join(',')
    )
  ].join('\n');

  return csvContent;
};

export const downloadEmailList = async (type: 'marketing' | 'communityNews') => {
  const csvContent = await exportEmailList(type);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${type}-subscribers.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};