import React from 'react';
import { Link } from 'react-router-dom';

export const Hero: React.FC = () => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <div className="flex justify-center lg:justify-start mb-4">
                <div className="p-3 bg-emerald-100 rounded-full">
                  <svg className="w-6 sm:w-8 h-6 sm:h-8 text-emerald-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                  </svg>
                </div>
              </div>
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Write Your Novel with</span>{' '}
                <span className="block text-emerald-600 xl:inline">Confidence</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Transform your story ideas into a compelling novel with our comprehensive novel planning guide.
                Master the essential elements of storytelling at your own pace.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    to="/signup"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 md:py-4 md:text-lg md:px-10"
                  >
                    Get Started
                  </Link>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    to="/login"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-emerald-700 bg-emerald-100 hover:bg-emerald-200 md:py-4 md:text-lg md:px-10"
                  >
                    Sign In
                  </Link>
                </div>
              </div>

              <div className="mt-8 sm:mt-10 bg-emerald-50 rounded-lg p-6 shadow-sm">
                <h2 className="text-lg font-semibold text-emerald-900 mb-4">
                  What's Inside Writer's Tome?
                </h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium text-emerald-800">📝 Comprehensive Writing Guide</h3>
                    <p className="text-emerald-600">Step-by-step modules covering plot development, character creation, thematic elements, and world building</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-emerald-800">💾 Save Your Progress</h3>
                    <p className="text-emerald-600">Create multiple projects and save all your work securely in the cloud, accessible from any device</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-emerald-800">👥 Writing Community</h3>
                    <p className="text-emerald-600">Connect with fellow writers, share experiences, get feedback on your work, and participate in discussions</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-emerald-800">🏆 Track Your Progress</h3>
                    <p className="text-emerald-600">Earn badges as you complete modules and exercises, helping you stay motivated and focused</p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <div className="h-56 w-full bg-emerald-50 sm:h-72 md:h-96 lg:w-full lg:h-full">
          <div className="h-full w-full flex items-center justify-center">
            <div className="max-w-lg p-8">
              <div className="space-y-4">
                <div className="h-4 bg-emerald-200 rounded w-3/4"></div>
                <div className="h-4 bg-emerald-200 rounded"></div>
                <div className="h-4 bg-emerald-200 rounded w-5/6"></div>
                <div className="h-4 bg-emerald-200 rounded w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};