import React from 'react';
import { ContactMessageList } from '../components/admin/ContactMessageList';
import { EmailManagement } from '../components/admin/EmailManagement';

export const AdminPage: React.FC = () => {
  return (
    <div>
      <EmailManagement />
      <ContactMessageList />
    </div>
  );
};