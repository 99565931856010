import { Topic } from './types';

export const characterDefinition = `This module will become the backbone of your story, giving you a rough framework to write freely and efficiently. The idea is to make sure you're heading in the right direction and avoid writer's block!`;

export const characterTopics: Topic[] = [
  {
    id: 'role',
    title: 'The Role of Characters',
    content: `Your characters' lives are where the plot unfolds.

This next piece of advice is so crucial that if you take nothing else away from this writing guide, I'll consider my efforts worthwhile: "Characters are Key." Let these words become a permanent fixture in your mind.

There's a vital reason why I want you to focus on your characters from the very beginning of the planning process—readers primarily care about characters above all else.

Emotionally engaging characters carry more weight than world-building, pacing, or even plot itself. They are the heart of your story.

Strong characters will keep readers returning to your book and following your series because characters are what readers relate to and form emotional connections with.

To write a book that truly captivates readers, you must create characters who come alive in their minds.

In this section, I'll provide tips relevant to character development in a broad sense. The purpose of this fundamental planning stage is to give you a framework for integrating characters to create a gripping narrative, rather than focusing on the nuances of individual character personalities and appearances.`
  },
  {
    id: 'writing-tips',
    title: 'Writing Characters Tips',
    content: `Tip 1 - Character Interactions

Characters should interact with and influence each other throughout the story. Show important decisions and turning points through dialogue and actions rather than internal monologue. Each character's choices should create ripples that affect others, generating tension and driving the plot forward.

When characters make significant decisions, reveal them through meaningful interactions that:
• Show rather than tell their choices
• Create tension between characters
• Impact the overall story direction
• Demonstrate unique personality traits

Tip 2 - Diverse Personalities

The characters' individual and differing personalities are what spark realistic interactions between characters and are what readers can emotionally relate to.

Remember that the anticipation created in your readers' minds as they follow their favorite character's journey is a powerful effect that you need to leverage as a writer. Much of that anticipation and suspense is generated by wanting to know how the characters will react and interact as the story progresses.`
  },
  {
    id: 'character-arcs',
    title: 'Character Arc Development',
    content: `Understanding how character arcs work is essential for creating compelling characters that readers will connect with emotionally.`,
    sections: [
      {
        id: 'arc-intro',
        title: 'Understanding Character Arcs',
        content: `A character arc is simply the transformation of the character as a result of the journey. 
        
Each character has their own arc or transformation, even the secondary characters, if you want them to come to life on the pages.

A character arc refers to the transformation or inner journey of a character throughout a story. It highlights how a character changes in response to events, conflicts, and their own experiences. This can involve growth, decline, or a realization of some sort. For example, a character might start as a selfish individual and evolve into a more selfless person by the story's end. Additionally, you can have a relationship arc between characters, such as an enemies to lovers arc, a freindship development, or a tragedy, as just a few examples.

In each step of a character's arc, you should make the character's feelings or motivations towards the plot known. All the main points of a character arc (transformation) should be catalyzed by their motives to the plot, or at least show how the plot is influencing their arc, to make their arc relevant to the story.

Any character that goes through a full transformation process becomes more real and likeable to the reader. Seeing that transformation process play out amongst the cast of other characters and seeing it change how they interact with each other is what creates a gripping story. Remember, a character's arc can be shown through the perspective of another character; you don't need to use each characer as a narration POV, just to show their arc. Seeing it witnessed by another charcter can be even more impactful.

Every step in a character arc should simultaneously point to the plot through the specific character's lens.`,
        sections: [
          {
            id: 'transformational-arc',
            title: 'Transformational Arc',
            content: `The most common type of character arc, where a character undergoes profound change in their beliefs, values, or identity throughout the story. The character starts with certain flaws, beliefs, or limitations and through their journey, transforms into someone who has overcome these initial constraints, leading to a redefinition of the character's self. A typical example is the 'hero's journey'. The transformational arc typically involves:
• Begins with a specific worldview, capabilities, or set of values that define them
• Resistance to change
• Series of challenges that force growth
• Transformation may be gradual or sudden, but is substantial
• Final demonstration of change`
          },
          {
            id: 'ascending-arc',
            title: 'Ascending Arc',
            content: `In an ascending arc, the character undergoes significant personal or moral growth. This arc involves:
• Starting with identifiable flaws or limitations that hinders their growth or leads to conflicts
• Facing challenges that promt the character to face their flaws
• Through challenges, the character learns valuable lessons about themselves, others, and the world aroubd them
• Applies what they've learned to overcome a major conflict or make a significant choice
• Demonstrates significant growth, inspiring others through example`
          },
          {
            id: 'descending-arc',
            title: 'Descending Arc',
            content: `A descending arc shows a character's decline or fall from grace. Often used for tragic heroes or villains, this arc includes:
• Starting from a position of virtue or power
• Series of compromising choices
• Gradual moral or personal decline
• Consequences of negative choices
• Potential for redemption or tragic end`
          },
          {
            id: 'flat-arc',
            title: 'Flat Arc',
            content: `In a flat arc, the character doesn't fundamentally change but instead provides a core of stability throughout the story. This arc might be used in a 'wise mentor' character, offering guidance and reassurance for the characters who are undergoing change throughout their journey. This arc features:
• Strong initial convictions or beliefs
• Testing of these beliefs through challenges
• Maintaining core values despite opposition
• Influencing others to change
• Proving the truth of their beliefs`
          },
          {
            id: 'relationship-arc',
            title: 'Relationship Arc',
            content: `A relationship arc focuses on how characters change through their interactions with others. This can include:
• Initial relationship dynamics
• Development of trust or conflict
• Changes in how characters view each other
• Evolution of relationships over time
• Impact on both characters' individual growth`
          }
        ]
      },
      {
        id: 'arc-types',
        title: 'Character Archetypes',
        content: `A character archetype is a universal symbol or a typical example of a character type that embodies certain traits and roles within a story. Archetypes are often seen as templates that recur across different narratives, like the Hero, the Mentor, or the Villain. They help convey familiar themes and ideas, allowing readers to easily recognize and understand character roles. While knowing character archetypes is useful, don't be too concerned about it when writing; they will typically form subconsiously. The aspect to be intentional about is each character's arc (transformational development), as discussed previously. Each archetypal character will experience their own unique character arc (transformation process). While there are many more archetypes you can discover and use in your writing, here are some common archetypes you can incorporate into your story:`,
        sections: [
          {
            id: 'hero',
            title: 'The Hero',
            content: `The classic protagonist who undergoes significant personal growth through challenges and trials. They typically start from a place of limitation or weakness and develop into someone capable of overcoming the main conflict. The hero's journey often involves:
• Receiving a call to adventure
• Initial reluctance or fear
• Meeting mentors and allies
• Facing increasingly difficult challenges
• A major crisis or revelation
• Final transformation and triumph`
          },
          {
            id: 'heroine',
            title: 'The Heroine',
            content: `A female protagonist who embodies strength, resilience, and determination. Their journey often includes:
• Breaking traditional constraints or expectations
• Finding their own voice and power
• Balancing different aspects of their identity
• Leading through empathy and wisdom
• Inspiring others to embrace their own strength`
          },
          {
            id: 'sidekick',
            title: 'The Sidekick',
            content: `A loyal companion who supports the hero while having their own growth journey. They often:
• Learn to believe in themselves
• Develop their own strength and identity
• Face choices between loyalty and personal desires
• Prove their worth in crucial moments`
          },
          {
            id: 'mentor',
            title: 'The Mentor',
            content: `A wise guide who helps others while possibly confronting their own past or limitations. Their arc might include:
• Overcoming past failures
• Learning to trust in new methods
• Accepting their own limitations
• Making the ultimate sacrifice for their protégé`
          },
          {
            id: 'trickster',
            title: 'The Trickster',
            content: `A complex character who challenges others through wit and deception. Their arc often involves:
• Using chaos to reveal truth
• Learning the consequences of their actions
• Finding a balance between mischief and responsibility
• Choosing between self-interest and greater good`
          },
          {
            id: 'sage',
            title: 'The Wise Old Sage',
            content: `A character who holds ancient knowledge or wisdom. Their arc might include:
• Passing on crucial knowledge
• Coming to terms with changing times
• Finding worthy successors
• Reconciling old ways with new challenges`
          },
          {
            id: 'villain',
            title: 'The Villain',
            content: `The antagonist whose actions oppose the hero. Their arc could involve:
• Revealing their tragic backstory
• Escalating their methods and stakes
• Facing the consequences of their choices
• Possible redemption or tragic fall`
          },
          {
            id: 'anti-hero',
            title: 'The Anti-Hero',
            content: `A morally ambiguous protagonist who doesn't fit the traditional hero mold. Their arc might include:
• Struggling with moral choices
• Finding their own code of ethics
• Balancing self-interest with greater good
• Earning redemption through difficult choices`
          },
          {
            id: 'rebel',
            title: 'The Rebel',
            content: `A character who challenges the established order or system. Their journey typically involves:
• Fighting against oppression or injustice
• Questioning traditional values
• Building alliances with unlikely allies
• Learning when to compromise and when to stand firm
• Finding a balance between revolution and responsibility`
          },
          {
            id: 'innocent',
            title: 'The Innocent',
            content: `A pure-hearted character whose naivety is both a strength and vulnerability. Their development often includes:
• Maintaining optimism in face of adversity
• Learning to navigate complex moral situations
• Discovering harsh truths about the world
• Preserving their core values while gaining wisdom
• Helping others rediscover hope and wonder`
          },
          {
            id: 'caregiver',
            title: 'The Caregiver',
            content: `A nurturing character who puts others' needs before their own. Their journey typically involves:
• Learning to balance self-care with caring for others
• Setting healthy boundaries
• Finding strength in compassion
• Making tough choices to protect those in their care
• Growing from enabler to empowerer`
          }
        ]
      }
    ]
  },
  {
    id: 'character-example',
    title: 'Character Planning Example: Frodo\'s Journey',
    content: `This is where the magic happens in character planning and I believe is the most important part in planning a novel.

Once you have plotted out the defining decisions and actions of each plot-related character, you will be able to write more freely, knowing you're heading in the right direction. Your character's place in the story will make sense, and you will always be excited about where you are heading.

In short, you will write faster!

Let's look at Frodo's key decisions and actions from Lord of the Rings as an example:

1. Decision to Accept the Quest: Frodo learns about the One Ring and decides to take on the responsibility of destroying it.

2. Departure from the Shire: He leaves his home to protect his friends and ensure the Ring is hidden from Sauron.

3. Formation of the Fellowship: Frodo agrees to let others join him, forming the Fellowship to aid in his quest.

4. Crossing into Danger: He faces various threats, such as the Nazgûl, and must decide to keep moving forward despite fear.

5. Crisis at Moria: After losing Gandalf, Frodo must choose whether to continue or turn back; he decides to press on.

6. Temptation of the Ring: He struggles with the Ring's power, deciding to resist its pull and trust his companions.

7. Separation from the Fellowship: After the encounter with Boromir, Frodo decides to leave the group to protect them from the Ring's influence.

8. Journey to Mordor: He navigates dangerous territories alone, making choices to avoid capture while staying focused on his goal.

9. Allies and Betrayal: Frodo meets Gollum, deciding to trust him as a guide despite his dubious nature.

10. Climactic Confrontation: At Mount Doom, Frodo battles with his inner conflict, ultimately deciding to claim the Ring for himself.

11. Resolution: Gollum intervenes, leading to the Ring's destruction and Frodo's realization of the journey's toll on him.

12. Return Home: Frodo returns to the Shire, reflecting on his experiences and the cost of his decisions.

This sequence of decisions and actions creates a clear path for Frodo's character development while maintaining focus on the main plot (the ring). Notice how each decision point either develops his character or advances the story—often both simultaneously. You may also notice that some of these points don't exactly match the true storyline. It's completely fine if, when writing the story, you end up tweaking the points you have plotted out, so don't be too concerned about knowing exactly what is going to happen throughout your entire story. Again, this is a starting point to ensure you know what each character's role is in the story and to help you write freely, so don't let it constrict your creativity.`
  },
  {
    id: 'character-profiles',
    title: 'Create Character Profiles',
    content: `Now it's time to create profiles for your characters. To start with you may only be able to plot out a few main actions or decisons, but as you begin to write your story and ideas begin to flow, you can come back and fill in more details. Just try to plot enough decisions, actions, or events for each character to take you right through to the end. As you can see with the example of Frodo, you don't need to know every little decision and choice made, but enough to direct your writing (and characters) towards the plot.`
  }
];